import React, { useState, useRef, useEffect } from "react";

function Zhikr() {
  const MAX_COUNTER = 999999999;
  const [counter, setCounter] = useState(() => {
    const savedCounter = localStorage.getItem("zhikrCounter");
    return savedCounter !== null ? parseInt(savedCounter, 10) : 0;
  });
  const [step, setStep] = useState(1);
  const [stepLabel, setStepLabel] = useState("1");
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [theme, setTheme] = useState("cupcake");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    localStorage.setItem("zhikrCounter", counter);
  }, [counter]);

  const handleUp = () => {
    setCounter((prevCounter) => {
      if (prevCounter + step <= MAX_COUNTER) {
        return prevCounter + step;
      } else {
        setIsMaxReached(true);
        return prevCounter;
      }
    });
  };

  const handleDown = () => {
    setCounter((prevCounter) =>
      prevCounter - step >= 0 ? prevCounter - step : 0
    );
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleReset = () => {
    setCounter(0);
  };

  const handleStepChange = (newStep) => {
    setStep(newStep);
    setStepLabel(newStep);
    setIsDropdownOpen(false);
    if (dropdownRef.current) {
      dropdownRef.current.open = false;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setCounter("");
    } else if (Number(value) <= MAX_COUNTER) {
      setCounter(Number(value));
    }
  };

  const handleBlur = () => {
    if (counter === "") {
      setCounter(0);
    }
    setIsEditing(false);
  };

  const getFontSize = () => {
    if (counter >= 999999999) {
      return "text-[30px]";
    } else if (counter > 99999999) {
      return "text-[40px]";
    } else if (counter > 999999) {
      return "text-[60px]";
    } else if (counter > 99999) {
      return "text-[80px]";
    } else {
      return "text-[100px]";
    }
  };

  const handleThemeChange = () => {
    const newTheme = theme === "cupcake" ? "dark" : "cupcake";
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  return (
    <div className="flex flex-col items-center justify-between h-800">
      <div className="absolute top-4 right-4">
        <label className="swap swap-rotate">
          <input type="checkbox" onChange={handleThemeChange} />

          <svg
            className="swap-on h-10 w-10 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
          </svg>

          <svg
            className="swap-off h-10 w-10 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
          </svg>
        </label>
      </div>

      <div className="w-[95%] h-[60%] flex flex-col justify-between mt-[20%] mb-[25%]">
        <div className="h-[30%] flex justify-center items-center">
          {isEditing ? (
            <input
              type="number"
              ref={inputRef}
              value={counter}
              onChange={handleInputChange}
              className={`input input-lg w-full h-24 text-center font-bold ${getFontSize()}`}
              onBlur={handleBlur}
              autoFocus
            />
          ) : (
            <input
              type="text"
              value={counter}
              readOnly
              className={`input input-lg w-full h-24 text-center font-bold ${getFontSize()}`}
              onClick={handleEdit}
            />
          )}
        </div>

        <div className="h-[10%] flex justify-center mt-8 mb-6">
          <details className="dropdown" ref={dropdownRef} open={isDropdownOpen}>
            <summary
              className="btn w-20 flex items-center justify-center"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span className="flex items-center">
                +{stepLabel}
                <svg
                  className={`w-4 h-4 ml-1 transition-transform ${
                    isDropdownOpen ? "rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </span>
            </summary>
            <ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 100].map((stepValue) => (
                <li key={stepValue}>
                  <a onClick={() => handleStepChange(stepValue)}>
                    +{stepValue}
                  </a>
                </li>
              ))}
            </ul>
          </details>
          &nbsp;
          <button className="btn w-20" onClick={handleEdit}>
            Düzenle
          </button>
          &nbsp;
          <label htmlFor="reset_modal" className="btn w-20">
            Sıfırla
          </label>
          <input type="checkbox" id="reset_modal" className="modal-toggle" />
          <div className="modal" role="dialog">
            <div className="modal-box">
              <h3 className="text-lg font-bold">Bilgilendirme</h3>
              <p className="py-4">Sayaç sıfırlanmıştır 🧮</p>
              <div className="modal-action">
                <label
                  htmlFor="reset_modal"
                  className="btn"
                  onClick={handleReset}
                >
                  Tamam
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[60%] flex justify-center items-center relative mt-8">
          <button
            className={`btn h-56 w-56 rounded-full shadow-lg border-4 ${
              theme === "dark"
                ? "bg-gray-700 text-white"
                : "bg-base-300 text-black"
            } text-[80px]`}
            onClick={handleUp}
          >
            ↑
          </button>
          <button
            className={`btn h-20 w-20 rounded-full shadow-lg absolute mt-56 border-4 ${
              theme === "dark"
                ? "bg-gray-700 text-white"
                : "bg-base-300 text-black"
            } text-[40px]`}
            onClick={handleDown}
          >
            ↓
          </button>
        </div>
      </div>

      <div className="w-full h-[5%] flex justify-center items-center text-[15px]">
        🇵🇸 Filistin için&nbsp;
        <a
          href="https://www.hudayivakfi.org.tr/online_bagis/filistin-yardimi"
          className="text-blue-500 hover:text-blue-700"
        >
          Bağış
        </a>
      </div>

      {isMaxReached && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Maksimum sayıya ulaşıldı 🛑</h3>
            <div className="modal-action">
              <button className="btn" onClick={() => setIsMaxReached(false)}>
                Tamam
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Zhikr;
